<template>
  <div id="templates">
    <div class="image-boxes mt3">
      <el-input
        @change="changeKeyword"
        placeholder="Search"
        class="mb3"
        v-model="search"
      ></el-input>
      <img
        class="box"
        @click="templateClicked(template)"
        v-for="(template, index) in templates"
        :src="template.preview"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapActions("projectsModule", ["getTemplates"]),
    ...mapGetters("elementsModule", ["isDirty"]),
  },
  data() {
    return {
      isLoadingTemplates: false,
      search: "",
      templates: [],
    };
  },
  mounted: function() {
    this.getPublicTemplates();
  },
  methods: {
    templateClicked: function(template) {
      const path = `/c/new?template=${window.btoa(template._id)}`;
      if (this.$route.fullPath === path) return;

      if (this.isDirty) {
        this.$confirm(
          "Do you want to replace the current project with this? The changes you have made are not saved yet.",
          "Warning",
          {
            confirmButtonText: "Replace project",
            cancelButtonText: "Stay on current project",
            type: "warning",
          }
        )
          .then(() => {
            this.$router.push(path);
          })
          .catch(() => {
            return;
          });
      } else {
        this.$router.push(path);
      }
    },
    changeKeyword: function(value) {
      this.getPublicTemplates();
    },
    getPublicTemplates: async function() {
      this.isLoadingTemplates = true;

      this.templates = await this.$store.dispatch(
        "projectsModule/getTemplates"
      );
      this.isLoadingTemplates = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
